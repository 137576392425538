export const educationData = [
    {
        id: 1,
        institution: 'Udemy',
        course: 'Information Technology Essentials',
        startYear: '2010',
        endYear: '2011'
    },
    {
        id: 2,
        institution: 'University of Florida',
        course: 'Bachelor of Applied Science (BASc), Computer science',
        startYear: '2006',
        endYear: '2010'
    },
]
