/* eslint-disable */
import boy1 from '../assets/svg/testimonials/boy1.svg'
import boy2 from '../assets/svg/testimonials/boy2.svg'
import boy3 from '../assets/svg/testimonials/boy3.svg'



export const testimonialsData = [
    {
        id: 1,
        name: 'Derek Olander',
        title: 'CEO',
        text: "Great job! The website looks seamless across different devices and screen sizes. Thanks for paying attention to details. Well done!",
        image: boy1
    },
    {
        id: 2,
        name: 'Justin Greenwald',
        title: 'CTO',
        text: "This is the only thing that I didn't have to spend time to sort out. It's tidy all by itself.",
        image: boy2
    },
    {
        id: 3,
        name: 'Peter Graham',
        title: 'Sales Executive',
        text: "The performance optimization you implemented has significantly improved the website's loading speed. The pages now load quickly, providing a smooth browsing experience for users.",
        image: boy3
    }
]

