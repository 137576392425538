export const experienceData = [
    {
        id: 1,
        company: 'To The Moon Media',
        jobtitle: 'Senior software developer',
        startYear: '2016',
        endYear: '2018'
    },
    {
        id: 2,
        company: 'Workday',
        jobtitle: 'Data Analyst',
        startYear: '2018',
        endYear: '2020'
    },
    {
        id: 3,
        company: 'Social Spot Media',
        jobtitle: 'Full-stack web developer',
        startYear: '2020',
        endYear: '2022'
    },
]
